import Dashboard from "./Dashboard";
import ProtectedRoute from "../auth/ProtectRoute";
import SidebarLayout from "./sidebar";



const DashboardRenderer = () => {
   const gettoken = localStorage.getItem('token')

    if (gettoken) {
        return < SidebarLayout />;
    } else {
        return <ProtectedRoute><Dashboard/></ProtectedRoute>
    }
}

export default DashboardRenderer;