var env = "live"
var configration = {};
if (env == "local") {
    configration = { 
        localhostBackend:"http://localhost:4000/",
        localhostFrontend:'https://localhost:3000/' 
    }
} 
else if (env == "demo") {
    configration = {
        localhostBackend:"https://api.vbitdemo.com/",
        localhostFrontend:'https://adminpanel.vbitdemo.com/', 
    }
} 
else {
    configration = {
        localhostBackend : 'https://api.valobitstake.com/', 
        localhostFrontend:'https://panel.valobitstake.com/',
    }
}


export default configration;